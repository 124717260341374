import { baseUrls } from '../utils/urls'
const aps1 = baseUrls.aps1

import { get } from "../utils/http"

// 齐套分析分页
export function getShiftGroupAnalysis(params) {
  return get(`${aps1}/gatherSetAnalysis/page`, params)
}

// 获取齐套分析树形结构
export function getShiftGroupAnalysisTree() {
  return get(`${aps1}/gatherSetAnalysis/treeList`)
}

// 导出齐套分析
export function exportShiftGroupAnalysis() {
  return get(`${aps1}/reportform/export/gatherSetAnalysis`)
}
