<script>
import { getShiftGroupAnalysis, getShiftGroupAnalysisTree, exportShiftGroupAnalysis } from '@/api/bundle'
import { getDay } from "@/utils/date.js"
export default {
  name: "bundleAnalysis",
  data() {
    return {
      input: '',
      total: 0,
      tableData: null,
      currentPage: 1,
      pageChange: 10,
      data: null,
      defaultProps: {
        children: 'childrenBom',
        label: 'materialName'
      },
      materialNo: '',
      subMaterialNo: '',
    }
  },
  async created() {
    this.getProduct()
  },
  methods: {
    async getProduct() {
      try {
        const res = await getShiftGroupAnalysisTree()
        if (res.code == 0) {
          this.data = res.data
        } else {
          this.$message.error({
            showClose: true,
            message:res.msg || '获取数据失败',
            duration: 0
          })
        }
      } catch (error) {
        console.error('获取树形数据失败 error：', error)
      }
    },
    async getModule(value = '', materialNo = '', subMaterialNo = '') {
      // 拿成品或组件数据
      let params = {
        value,
        materialNo,
        subMaterialNo,
        page: this.currentPage,
        size: this.pageChange
      }
      try {
        const res = await getShiftGroupAnalysis(params)
        if (res.code == 0) {
          const { records, total } = res.data
          this.tableData = records
          this.total = total
        } else {
          this.$message.error({
            showClose:true,
            message:res.msg || '获取数据失败',
            duration:0
          })
        }
      } catch (error) {
        console.error('获取成品或组件数据失败 error：', error)
      }
    },
    searchHandler(val) {
      this.getModule(val)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getModule('', this.materialNo || '', this.subMaterialNo || '')
    },
    handleSizeChange(val) {
      this.pageChange = val
      this.getModule('', this.materialNo || '', this.subMaterialNo || '')
    },
    handleNodeClick(data) {
      console.log(data)
      if (data.materialNo) {
        this.materialNo = data.materialNo
        this.subMaterialNo = ''
      } else if (data.subMaterialNo) {
        this.subMaterialNo = data.subMaterialNo
        this.materialNo = ''
      }
      this.getModule('', this.materialNo || '', this.subMaterialNo || '')
    },
    async exportFn() {
      try {
        const res = await exportShiftGroupAnalysis()
        this.filename = `齐套分析 ${getDay()}.xlsx`
        this.url = window.URL.createObjectURL(res)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = this.url
        link.setAttribute('download', this.filename)
        document.documentElement.appendChild(link)
        link.click()
        document.documentElement.removeChild(link)
      } catch (error) {
        console.error('导出失败 error：', error)
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="container">
      <div class="top">
        <div class="topTitle">齐套分析</div>
        <div class="topRight">
          <el-tooltip v-premiSub="'齐套分析_导出'" class="item" effect="dark" content="导出" placement="top">
            <img src="@/assets/images/production/export.png" alt="" @click="exportFn">
          </el-tooltip>
          <el-input placeholder="" v-premiSub="'齐套分析_搜索'" suffix-icon="el-icon-search" size="small" v-model="input"
            @change="searchHandler">
          </el-input>
        </div>
      </div>
      <div class="main">
        <div class="mainLeft">
          <!-- 齐套分析目录 -->
          <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick">
            <span class="custom-tree-node" slot-scope="{ node,data }">
              <img src="@/assets/images/common/file2.png" alt="" class="iconFile" v-if="data.materialName">
              <span>{{ data.materialName }}</span>
              <div v-if="data.subMaterialName">
                <img src="@/assets/images/common/file1.png" alt="" class="iconFile">
                <span>{{ data.subMaterialName }}</span>
              </div>
            </span>
          </el-tree>
        </div>
        <div class="mainRight">
          <el-table :data="tableData" tooltip-effect="dark" style="width: 100%">
            <div v-if="materialNo">
              <el-table-column prop="id" label="序号" fixed="left">
              </el-table-column>
              <el-table-column prop="orderNo" label="订单号" >
              </el-table-column>
              <el-table-column prop="materialNo" label="物料编码">
              </el-table-column>
              <el-table-column prop="materialName" label="产品名称" >
              </el-table-column>
              <el-table-column prop="partCode" label="图号">
              </el-table-column>
              <el-table-column prop="versionCode" label="版本号" >
              </el-table-column>
              <el-table-column prop="requiredQuantity" label="订单数量">
              </el-table-column>
              <el-table-column prop="shortageQuantity" label="欠套数量">
              </el-table-column>
              <el-table-column prop="gatherSetDate" label="齐套日期" >
              </el-table-column>
            </div>
            <div v-if="subMaterialNo">
              <el-table-column prop="id" label="序号" fixed="left" >
              </el-table-column>
              <el-table-column prop="subOrderNo" label="订单号">
              </el-table-column>
              <el-table-column prop="subMaterialNo" label="物料编码">
              </el-table-column>
              <el-table-column prop="subMaterialName" label="产品名称" >
              </el-table-column>
              <el-table-column prop="subPartCode" label="图号">
              </el-table-column>
              <el-table-column prop="subVersionCode" label="版本号" >
              </el-table-column>
              <el-table-column prop="subRequiredQuantity" label="订单数量">
              </el-table-column>
              <el-table-column prop="subShortageQuantity" label="欠套数量">
              </el-table-column>
              <el-table-column prop="subGatherSetDate" label="齐套日期" >
              </el-table-column>
            </div>
          </el-table>
          <div class="totalFlex">
            <span>共{{ total }}条</span>
            <span>
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange
                layout="prev, pager, next,sizes, jumper" :total=total>
              </el-pagination>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: 20px;
  background-color: #232323;
  border-radius: 8px;

  .top {
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    line-height: 70px;

    .topTitle {
      font-size: 20px;
      color: #fff;
    }

    .topRight {
      display: flex;
      align-items: center;

      img {
        margin-right: 25px;
        width: 22px;
        cursor: pointer;
      }

      .el-input {
        width: 180px !important;

        ::v-deep .el-input__inner {
          border: 1px solid #4F4F4F !important;
          border-radius: 3px;
        }
      }
    }

  }

  .main {
    width: 100%;
    height: auto;
    max-height: 685px;
    border-top: 1px solid #3E3E3E;
    display: flex;

    .mainLeft,
    .mainRight {
      height: 625px;
    }

    .mainLeft {
      padding: 20px 10px;
      overflow: scroll;
      border-right: 1px solid #3E3E3E;
      width: 240px;
      background-color: #212224;

      .el-tree {
        width: 300px;
      }
    }

    .mainRight {
      padding: 20px;
      width: 1340px;
      overflow-y: scroll;
      .el-table{

      }
    }

  }

  .totalFlex {
    margin-top: 25px;
    margin-bottom: 20px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
    align-items: center;
  }
}

::v-deep {
  .el-pager li {
    color: #fff !important;
  }

  .el-table th.el-table__cell {
    background-color: #2c2c2c;
  }

}

.iconFile {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.custom-tree-node,
.el-tree-node__content {
  display: flex;
  align-items: center;
}

::v-deep .el-tree-node__content {
  margin-bottom: 8px
}

::v-deep .el-icon-caret-right::before {
  content: '';
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #fff;
  border: 0px solid #454545 !important;
}

$c: #AEAEAE;

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  color: $c;
}

::v-deep .el-pagination.is-background .el-pager li {
  border: 1px solid #454545 !important;

}

::v-deep .el-pagination__jump {
  color: $c;
}

::v-deep .el-input__inner {
  color: $c;
  border: 1px solid #4F4F4F;
}

::v-deep .el-pagination.is-background .btn-prev {
  color: $c;
}

::v-deep .el-pagination.is-background .btn-next {
  color: $c;
}

::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 5px !important;
}
</style>
